export const api_header = "http://test.toi.anchong001.com/monitor";

// export const api_header = "http://localhost:8080/monitor";
export const
    share_host = "http://test.toi.anchong001.com/shared"

export function get(url, params) {
    let paramStr = "";
    if (params != null) {
        console.log("请求", url, params)
        let temp = Object.keys(params);
        if (temp.length > 0) {
            let result = temp.map(key => `${key}=${params[key]}`)
                .reduce((a, b) => `${a}&${b}`)
            paramStr = '?' + result;
        }
    }
    return fetch(`${url}${paramStr}`,
        {
            method: 'GET',
            credentials: 'include',
            headers: {
                'content-type': "application/json"
            }
        }
    )
        .then(res => {
            console.log("返回:", res)
            if (res.status === 200) {
                return res.json()
            } else if (res.status === 304) {
                throw new Error("请先登录");
            }
        })
        .then(data => {
            if (data.code !== '000000') {
                throw new Error(data.msg);
            } else {
                console.log("返回结果", data)
                return data
            }
        });
}

export function post(url, body = {}) {
    return fetch(`${url}`,
        {
            method: 'POST',
            credentials: 'include',

            headers: {
                'content-type': 'application/json'
            }
            , body: JSON.stringify(body)
        }
    )
        .then(res => res.json())
        .then(data => {
            if (data.code !== '000000') {
                throw new Error(data.msg);
            }
        });
}


export function postForm(url, data) {


    return fetch(`${url}`,
        {
            body: data,
            method: "post",
            credentials: 'include',

        }).then(res => res.json())
        .then(data => {
            if (data.code !== '000000') {
                throw new Error(data.msg);
            }
        });
}
