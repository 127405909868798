import React, {useEffect, useState} from "react";
import {pageIotData} from "../../../request/RealDataRequest";
import {listCmdsSimple} from "../../../request/CmdRequest";
import {Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import SelectBox from "../../../Components/SelectBox";
import DateTimeLinkPicker from "../../../Components/DateTimeLinkPicker";
import CTable from "../../../Components/CTable";
import KV from "../../../Components/KV";
import {format, getBeforeDays, getEndTime, getStartTime} from "../../../utils/DateUtil";
import _ from "lodash";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {useSnackbar} from "notistack";

export default function AllExchangeData(props) {

    const {sn} = props;

    const [cmdInfos, setCmdInfos] = useState([]);

    const [dataDialogOpen, setDataDialogOpen] = useState(false);

    const [dialogData, setDialogData] = useState({});


    console.log(format(getEndTime(new Date())))

    const [searchParams, setSearchParams] = useState({
        pageNum: 0,
        pageSize: 100,
        deviceSn: sn,
        startTime: format(getStartTime(getBeforeDays(1))),
        endTime: format(getEndTime(new Date()))
    });

    const [pageData, setPageData] = useState({
        dataList: [],
        totalCount: 0,
        deviceSn: sn
    });
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();


    const search = (pageNum, pageSize) => {
        pageIotData({...searchParams, pageNum: pageNum, pageSize: pageSize})
            .then(data => {
                setPageData({
                    dataList: data.data.dataList,
                    totalCount: data.data.totalCount
                })
            }).catch(err => enqueueSnackbar(err.message))
    }

    useEffect(() => {
        search(0, searchParams.pageSize)
    }, [])


    useEffect(() => {
        listCmdsSimple({}).then(data => {
            setCmdInfos(data.data)
        }).catch(err => console.log(err))
    }, [])

    const sockets = [
        {name: '1', value: 1},
        {name: '2', value: 2},
        {name: '3', value: 3},
        {name: '4', value: 4},
        {name: '5', value: 5},
        {name: '6', value: 6},
        {name: '7', value: 7},
        {name: '8', value: 8},
        {name: '9', value: 9},
        {name: '10', value: 10},
        {name: '11', value: 11},
        {name: '12', value: 12},
        {name: '13', value: 13},
        {name: '14', value: 14},
        {name: '15', value: 15},
        {name: '16', value: 16},
    ];

    return (
        <Box>
            <Box className={'flex flex-wrap gap-3'}>
                <SelectBox nameTag={"cmdDesc"} valueTag={"cmdId"} selectList={cmdInfos} isSearch label={"指令"}
                           handleChange={(value) => {
                               console.log(value)
                               setSearchParams({
                                   ...searchParams,
                                   cmdId: value
                               })
                           }}/>
                <SelectBox selectList={sockets} nameTag={'name'} valueTag={'value'}
                           isSearch
                           label={"端口"}
                           handleChange={value => {
                               console.log("lalala", value)
                               setSearchParams({
                                   ...searchParams,
                                   outletNum: value
                               })
                           }}/>
                <DateTimeLinkPicker
                    handleStartTime={(value) => {
                        console.log("开始时间111:", value)
                        setSearchParams({
                            ...searchParams,
                            startTime: value
                        })
                    }}
                    handleEndTime={value => {
                        setSearchParams({
                            ...searchParams,
                            endTime: value
                        })
                    }}
                    beforeDays={1}/>
                <Button variant={"contained"} color={"primary"} onClick={() => {
                    search(0, searchParams.pageSize)
                }}>搜索</Button>
            </Box>
            <Box style={{marginTop: 20}}>
                <CTable

                    headRow={[
                        {name: "时间", key: "time", render: (row) => row.time.substr(0, 19)},
                        {
                            name: "命令码", key: "cmdId", render: row => {
                                if (row.direction === 1) {
                                    return <Box> <ArrowUpwardIcon/>{row.cmdId}</Box>
                                } else {
                                    return <Box> <ArrowDownwardIcon/>{row.cmdId}</Box>
                                }
                            }
                        },
                        {name: "说明", key: "note"},
                        {
                            name: "数据", key: "data", render: row => {
                                let rowData = JSON.parse(row.data.toString())
                                return renderIotData(row.cmdId, rowData)
                            }
                        },

                        {
                            name: "版本/类型", render: row => {
                                return row.version + "/" + row.appId
                            }
                        },

                        {
                            name: "ip",
                            key: "ip"
                        },

                    ]}
                    isPagination={true}
                    page={searchParams.pageNum}
                    dataRows={pageData.dataList}
                    totalCount={pageData.totalCount}
                    rowsPerPage={searchParams.pageSize}
                    onPageChange={(evt, page) => {
                        console.log()
                        setSearchParams({
                            ...searchParams,
                            pageNum: page,
                            pageSize: searchParams.pageSize
                        })
                        search(page, searchParams.pageSize)
                    }}
                    onRowsPerPageChange={(evt) => {
                        setSearchParams({
                            ...searchParams,
                            pageSize: evt.target.value
                        })
                        search(0, evt.target.value)
                    }}
                />
            </Box>
            <Dialog open={dataDialogOpen}
                    onClose={() => {
                        setDataDialogOpen(false)
                    }}>
                <DialogTitle>数据</DialogTitle>
                <DialogContent style={{width: '500px', height: '100%', paddingTop: 10}}>
                    <Box>
                        <KV content={dialogData} kRender={(it) => {

                        }} vRender={(val) => {
                            return val.toString().substr(0, 10)
                        }}/>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant={"outlined"} color="primary" onClick={() => {
                        setDataDialogOpen(false)
                    }}>关闭</Button>
                </DialogActions>
            </Dialog>
        </Box>

    )
}

function renderData(key) {
    switch (key) {
        case "body":
            return "内容"
        case "cmdId":
            return "指令"
        case "sn":
            return "sn"
        case "outletQty":
            return "端口数量"
        case "status":
            return "状态"
        case "updateVer":
            return "版本号"
        case "ackBlockId":
            return "块编号"
        case "imsi":
            return "物联网卡号"
        case "signalIntensity":
            return "信号"
        case "countInitModule":
            return "模块初始化次数"
        case "countInitTCP":
            return "通信初始化次数"
        case "voltage":
            return "电压"
        case "hwStatus":
            return "硬件状态"
        case "manufacturerId":
            return "制造商id"
        case "equipYear":
            return "生产年份"
        case "equipMonth":
            return "生产月份"
        case "equipDay":
            return "生产日"
        case "isPositive":
            return "正负"
        case "temperature":
            return "温度"
        case "buzzerStatus":
            return "蜂鸣器状态"
        case "thresholdStopCurrent":
            return "电流阀值"
        case "freeChargeTime":
            return "浮充时间"
        case "errorNo":
            return "错误码"
        case "isCheckPlugOff":
            return "是否检测插口关闭"
        case "outletContentList":
            return "端口列表"
        case "cycleNum":
            return "周期"
        case "outletNum":
            return "插口"
        case "totalEnergy":
            return "电量"
        case "currentCurrent":
            return "电流"
        case "currentPower":
            return "功率"
        case "currentVoltage":
            return "电压"
        case "pData":
            return "块"
        case "checksum":
            return "校验和"
        case "blockId":
            return "块编号"
        case "optCode":
            return "操作码"
        case "checkSum":
            return "校验码"
        case "outletStatus1":
            return "插口1"
        case "outletStatus2":
            return "插口2"
        case "outletStatus3":
            return "插口3"
        case "outletStatus4":
            return "插口4"
        case "outletStatus5":
            return "插口5"
        case "outletStatus6":
            return "插口6"
        case "outletStatus7":
            return "插口7"
        case "outletStatus8":
            return "插口8"
        case "outletStatus9":
            return "插口9"
        case "outletStatus10":
            return "插口10"
        case "outletStatus11":
            return "插口11"
        case "outletStatus12":
            return "插口12"
        case "outletStatus13":
            return "插口13"
        case "outletStatus14":
            return "插口14"
        case "outletStatus15":
            return "插口15"
        case "outletStatus16":
            return "插口16"
        case "totalTime":
            return "总时长"
        case "outletCurrent1":
            return "插口1电流"
        case "outletCurrent2":
            return "插口2电流"
        case "outletCurrent3":
            return "插口3电流"
        case "outletCurrent4":
            return "插口4电流"
        case "outletCurrent5":
            return "插口5电流"
        case "outletCurrent6":
            return "插口6电流"
        case "outletCurrent7":
            return "插口7电流"
        case "outletCurrent8":
            return "插口8电流"
        case "outletCurrent9":
            return "插口9电流"
        case "outletCurrent10":
            return "插口10电流"
        case "outletCurrent11":
            return "插口11电流"
        case "outletCurrent12":
            return "插口12电流"
        case "outletCurrent13":
            return "插口13电流"
        case "outletCurrent14":
            return "插口14电流"
        case "outletCurrent15":
            return "插口15电流"
        case "outletCurrent16":
            return "插口16电流"
        case "cardId":
            return "卡号"
        case "totalUseTime":
            return "总时长"
        case "outletUseResult":
            return "结束原因"
        case "manufactorId":
            return "制造商编号"
        case "balance":
            return "余额"
        case "equipmentCode":
            return "sn"
        case "soc":
            return "soc"
        case "gunNo":
            return "枪号"
        case "isGun":
            return "是否插枪"
        case "orderNo":
            return "流水号"
        case "isHoming":
            return "是否归位"
        case "electricity":
            return "电流"
        case "gunTemperature":
            return "枪线温度"
        case "gunCode":
            return "枪线编码"
        case "cellTemperature":
            return "电池组最高温度"
        case "chargingTime":
            return "累计充电时间"
        case "remainTime":
            return "累计充电时间"
        case "changingDegree":
            return "充电度数"
        case "lossDegree":
            return "计损充电度数"
        case "hasChargingMoney":
            return "金额"
        case "hardwareFault":
            return "硬件故障"
        case "equipmentType":
            return "桩类型"
        case "gunNum":
            return "充电枪数量"
        case "version":
            return "程序版本"
        case "sim":
            return "sim卡"
        case "operator":
            return "运营商"
        case "comVersion":
            return "通信协议版本"
        case "netType":
            return "网络链接类型"
        case "startType":
            return "启动方式"
        case "cardNo":
            return "卡号"
        case "vin":
            return "vin码"
        case "startRes":
            return "启动结果"
        case "failReason":
            return "失败原因"
        case "stopReason":
            return "停止原因"
        case "curTime":
            return "时间"
        case "startTime":
            return "开始时间"
        case "endTime":
            return "结束时间"
        case "sPrice":
            return "尖价格"
        case "sPower":
            return "尖电量"
        case "sLossPower":
            return "计损尖电量"
        case "sMoney":
            return "峰金额"
        case "pPrice":
            return "峰价格"
        case "pPower":
            return "峰电量"
        case "pLossPower":
            return "计损峰电量"
        case "pMoney":
            return "峰金额"
        case "fPrice":
            return "平价格"
        case "fPower":
            return "平电量"
        case "fLossPower":
            return "计损平电量"
        case "fMoney":
            return "平金额"
        case "vPrice":
            return "谷价格"
        case "vPower":
            return "谷电量"
        case "vLossPower":
            return "计损谷电量"
        case "vMoney":
            return "谷金额"
        case "startPower":
            return "电表总起值"
        case "endPower":
            return "电表总止值"
        case "totalPower":
            return "总电量"
        case "lossPower":
            return "计损总电量"
        case "money":
            return "消费金额"
        case "carSign":
            return "vin码"
        case "tradeSign":
            return "交易标识"
        case "tradeDate":
            return "交易日期"
        case "loginRes":
            return "登录结果"
        case "confirmRes":
            return "确认结果"
        case "logicCardNo":
            return "逻辑卡号"
        case "physicalCardNo":
            return "物理卡号"
        case "canWork":
            return "是否工作"
        case "maxPower":
            return "最大功率"

        default:
            return "空"
    }
}


function renderIotData(cmdId, rowData) {
    if (cmdId === 161) {
        let num = parseInt(rowData.outletQty)
        let validStatus = _.filter(rowData, (val, key) => {
            return parseInt(key.slice(12)) <= num
        })
        return _.map(validStatus, (val, key) => {
            if (val === 0) {
                return <Chip color={"default"} size={"small"} label={key + 1}/>
            } else if (val === 1) {
                return <Chip color={"primary"} size={"small"} label={key + 1}/>
            } else {
                return <Chip color={"error"} size={"small"} label={key + 1}/>
            }
        })
    } else if (cmdId === 170) {
        let imsi = _.map(rowData, (val, key) => {
            return val
        })
            .reduce((a, b) => '' + a + b)
        return <Typography fontSize={12}>imsiNo:{imsi}</Typography>
    } else {
        let rs = {}
        if (rowData.body != null) {
            let body = rowData.body
            delete rowData.body
            rs = {
                ...rowData,
                ...body
            }
        } else {
            rs = rowData
        }


        return _.map(rs, (val, key) => {
                return <Box className="flex justify-between w-32">

                    <Typography fontSize={12}>{renderData(key)}:</Typography>
                    <Typography fontSize={12}>{val.toString().substr(0, 16)}</Typography>
                </Box>
            }
        )
    }
}

function renderBody(key, data) {
    if (key === "body") {
        return _.map(data, (val, key) => {
            return <Typography fontSize={12}>{renderData(key)}:{val.toString().substr(0, 4)}</Typography>
        })
    } else {
        return <Typography fontSize={12}>{data.toString().toString().substr(0, 16)}</Typography>
    }
}
